<template>
    <div class="chat-body">
        <div
            v-if="!user.is_manager || (user.is_manager && chat.opened)"
            ref="messages"
            class="messages ver-scroll"
            :class="{ 'stop-scrolling': loading }"
            @scroll="onScroll"
        >
            <div class="open-chat">
                <span class="f-15 text">반가운 인사로 대화를 시작해보세요! 😊</span>
            </div>
            <div
                class="message-row"
                :class="[shouldAddMoreMargin(idx), isMine(message) ? 'right' : 'left']"
                :key="(message || {}).id"
                v-for="(message, idx) in messages"
            >
                <UnreadSeparator v-if="showUnreadSeparator(message, idx)" />

                <DailySeparator v-if="showDailySeparator(idx)" :message="message" />

                <template v-if="message.mtype !== 'open-chat'">
                    <div v-if="!isMine(message)" class="profile-and-name flex-row">
                        <div class="flex-wrap" v-if="message.mtype !== 'close-chat'">
                            <img
                                @click="onClickProfileImage(vb(message) || user)"
                                class="img-profile flex-wrap"
                                v-lazy="sender(message).photo_url"
                                v-if="isEnableUser || message.mtype === 'open-chat'"
                            />
                            <img
                                @click="onClickProfileImage(vb(message) || user)"
                                class="img-profile flex-wrap"
                                v-else
                                v-lazy="$blankProfile"
                            />
                        </div>
                        <div
                            class="name flex-wrap m-b-8"
                            v-if="message.mtype !== `close-chat`"
                            v-html="
                                isEnableUser || message.mtype === 'open-chat' ? sender(message).nickname : '휴면 회원'
                            "
                        />
                    </div>
                    <Message class="flex-wrap" :message="message" :me="isMine(message)" />
                </template>
            </div>
        </div>
        <div v-else class="chat-closed">
            <img v-if="$isDormant(user)" :src="require('@/assets/images/blank_profile.svg')" />
            <img
                v-else
                @click="onClickProfileImage(user)"
                v-lazy="user.photo_url ? user.photo_url : $alternativeImg(user.gender)"
            />
            <div class="title" v-if="!user.is_manager" v-html="`${$nameAndNick(user)} <br> 님과 대화를 시작해요`" />
            <div
                class="manager-comment"
                v-else
                v-html="
                    `전문 커플매니져가 연락을 드릴 예정이니, 잠시만 기다려주세요<br>(오후 7시 이후, 휴일에는 연락드리기 어려울 수 있습니다.)`
                "
            />
        </div>
    </div>
</template>
<script>
import DailySeparator from './DailySeparator'
import UnreadSeparator from './UnreadSeparator'

import Message from './message/Message'

// import chatService from '@/services/chat'

export default {
    name: 'ChatBody',
    props: ['unreadCount'],
    components: { DailySeparator, UnreadSeparator, Message },
    data: () => ({
        loading: false,
        firstUnreadMessage: null,
    }),
    computed: {
        isEnableUser() {
            const { is_dormant: dormant, enabled, hidden } = this.chat.target_chat_user
            // const vb = this.vb
            return !dormant && enabled && !hidden
        },
        messages() {
            return (this.chat.$$messages || []).slice().reverse()
        },
        user() {
            return this.chat.target_chat_user
        },
        userAvailable() {
            return this.user.enabled && !this.user.hidden && !this.user.is_dormant
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        chatType() {
            return this.chat.chat_type
        },
        me() {
            return this.$store.getters.me || {}
        },
    },
    methods: {
        async initScrollPosition() {
            if (this.unreadCount > this.messages.length) {
                const loadMessages = () => {
                    this.chat.$$firstMessageId = this.messages[0].id
                    return this.$store.dispatch('loadMessages', {
                        firstId: this.chat.$$firstMessageId,
                        chatId: this.chat.id,
                    })
                }

                const loopCount = parseInt(this.unreadCount / this.messages.length)

                for (let i = 0; i < loopCount; i += 1) {
                    await loadMessages()
                }
            }

            const dom = this.$refs.messages
            const unreadElem = document.querySelector('.unread-separator')
            if (unreadElem) {
                unreadElem.scrollIntoView(true)
                if (dom.scrollTop + dom.clientHeight < dom.scrollHeight) {
                    dom.scrollTop -= dom.clientHeight / 3
                }
            } else {
                await this.$waitFor(200)
                this.$scroll.down(dom, false) // 메시지 랜더링이 채 끝나기 전에 scrolldown 하면 밑으로 제대로 내려가지 못함
            }
        },
        async loadMoreMessages() {
            if (this.chat.$$loadMore === false) {
                this.loading = false
                return
            }

            const dom = this.$refs.messages
            const scrollHeightBefore = dom.scrollHeight // 메시지 추가 전 스크롤 높이 기억

            try {
                await this.$store.dispatch('loadMessages', {
                    firstId: this.chat.$$firstMessageId,
                    chatId: this.chat.id,
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$nextTick(() => {
                    const scrollHeightAfter = dom.scrollHeight // 메시지 추가 후 스크롤 높이
                    setTimeout(() => {
                        dom.scrollTop = scrollHeightAfter - scrollHeightBefore
                    }, 0) // 이상해보이지만 ios 에서는 큐에 넣어두어야 올바르게 실행됨
                    setTimeout(() => {
                        this.loading = false
                    }, 100) // 충분한 시간을 두고 false 처리해줘야 여러번 호출 되는 것을 방지할 수 있음
                })
            }
        },
        vb(message) {
            const content = this.$mustParse(message.content) || {}
            if (!content.is_vb) return

            return {
                photo_url: require('@/assets/images/logo_symbol.png'),
                nickname: this.$translate('VANJJOK'),
            }
        },
        sender(message) {
            const vb = this.vb(message)
            if (vb) {
                return this.vb(message)
            }
            return {
                nickname: `<span class="f-medium m-l-2"> ${this.$nameAndNick(this.user)}</span>`,
                photo_url: this.user.photo_url || this.$alternativeImg(this.user.gender),
            }
        },
        isMine(message) {
            const content = this.$mustParse(message.content) || {}
            if (content.is_vb) return false

            return message.user_id === this.me.id
        },
        onScroll(event) {
            if (this.loading) return

            const scrollTop = event.target.scrollTop
            if (scrollTop > 100 || !this.messages || this.messages.length === 0) {
                return
            }

            this.loading = true
            this.chat.$$firstMessageId = this.messages[0].id
            this.loadMoreMessages()
        },
        onClickProfileImage(user) {
            if (this.user.is_manager) return

            if (!this.isEnableUser) {
                this.$modal.basic({
                    title: '안내',
                    body: `죄송합니다. 상대가 휴면 신청을 하여 프로필을 열람할 수 없습니다.`,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }

            if (!user.id) return
            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.user.id,
                    from: 'ChatsPage',
                },
            })
        },
        showDailySeparator(idx) {
            if (idx === 0) {
                return true
            }

            const curMessage = this.messages[idx]
            if (!curMessage || !curMessage.created_at) return false

            const prevMessage = this.messages[idx - 1]
            return (
                this.$moment(prevMessage.created_at).format('YYYY-MM-DD') !==
                this.$moment(curMessage.created_at).format('YYYY-MM-DD')
            )
        },
        showUnreadSeparator(message, idx) {
            if (message.mtype === 'close-chat') return false
            if (this.unreadCount === 0 || this.messages.length === 1) return false

            if (
                this.firstUnreadMessage &&
                this.firstUnreadMessage.id === message.id &&
                this.firstUnreadMessage.user_id !== this.me.id &&
                !this.firstUnreadMessage.mtype.includes('open-chat')
            )
                return true

            if (this.messages.length - this.unreadCount === idx) {
                if (!this.firstUnreadMessage) {
                    this.firstUnreadMessage = message
                    return true
                }
            }

            return false
        },
        shouldAddMoreMargin(msgIdx) {
            const curMessage = this.messages[msgIdx]
            const nextMessage = this.messages[msgIdx + 1]
            if (!curMessage || !nextMessage) return

            if (curMessage.user_id !== nextMessage.user_id) {
                return 'm-b-20'
            }

            if (['text', 'photo', 'multi-photo'].indexOf(curMessage.mtype) === -1) {
                return 'm-b-16'
            }
        },
        onChatInputFocused() {
            const dom = this.$refs.messages
            console.log(dom)
            if (!dom) return

            const { scrollHeight, clientHeight, scrollTop } = dom
            console.log(scrollHeight, clientHeight, scrollTop)
            if (scrollHeight <= clientHeight + scrollTop + 32) {
                setTimeout(() => {
                    this.$scroll.down(dom, true)
                }, 400)
            }
        },
        async onExit() {
            // await chatService.closeChat({
            //     user_id: this.chat.target_chat_user.id,
            //     chat_id: this.chat.id,
            // })
            this.$stackRouter.pop()
        },
    },
    async mounted() {
        if (!this.chat.opened) {
            if (!this.isEnableUser) {
                await this.$modal.basic({
                    title: '안내',
                    body: `죄송합니다. 상대가 휴면 신청을 하여 프로필을 열람할 수 없습니다.`,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                this.$router.go(-1)
                return
            }
        }
        this.$bus.$on('chatInputFocus', this.onChatInputFocused)
        this.$nextTick(() => {
            this.initScrollPosition()
        })
    },
    beforeDestroy() {
        this.$bus.$off('chatInputFocus', this.onChatInputFocused)
    },
}
</script>

<style lang="scss" scoped>
.chat-body {
    position: relative;

    .open-chat {
        width: 100%;
        background: rgba($purple-primary, 0.08);
        color: $grey-09;
        border-radius: 12px;
        @include center;

        span {
            margin: 9px auto;
        }
    }
    .chat-closed {
        height: 100%;
        align-items: center;
        background: white;
        @include f-medium;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: solid 1px $grey-03;
        }

        .title {
            color: black;
            font-size: 18px;
            font-weight: 500;
            margin-top: 16px;
            text-align: center;
        }

        .manager-comment {
            color: black;
            font-size: 14px;
            // font-weight: 500;
            margin-top: 16px;
            text-align: left;
            max-width: 80vw;
        }

        .btn {
            height: 40px;
            padding: 0 48px;
            line-height: 40px;
            text-align: center;
            color: white;
            font-size: 15px;
            margin-top: 52px;
            border-radius: 8px;
            background-color: $purple-primary;
        }

        .heart {
            font-size: 12px;
            margin-top: 10px;
            color: $grey-07;
        }
    }
    .message-row {
        .profile-and-name {
            .img-profile {
                width: 28px;
                height: 28px;
                background-color: white;
                @include center;

                .material-icons {
                    color: $grey-04;
                    font-size: 20px;
                }
            }
            .name {
                margin-left: 8px;
                font-size: 12px;
                color: $grey-08;
                @include items-center;
                @include f-regular;
            }
        }
        &:last-child {
            padding-top: 0;
        }
        &.left {
            .message {
                margin-top: -6px;
                margin-left: 36px;
            }
        }
    }
    .messages {
        padding: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .stop-scrolling {
        height: 100%;
        overflow: hidden;
    }
}
</style>
