<template>
    <div class="chatroom">
        <header class="header">
            <ChatHeaderBar @onClickInfoBtn="info = true" />
            <FloatingComponents :chat="chat" />
        </header>
        <DirectChatInfo v-if="info" @exit="info = false" />
        <TempChatBody v-if="tempChatUser" />
        <ChatBody v-else :unreadCount="unreadCount" class="flex-fill" />

        <ChatInput class="flex-wrap" :tempChatUser="tempChatUser" />
    </div>
</template>

<script>
import ChatBody from './components/ChatBody'
import ChatInput from './components/ChatInput'
import ChatHeaderBar from '@/components/app/ChatHeaderBar'
import DirectChatInfo from '@/routes/chat/chatroom/components/floating-components/DirectChatInfo'
import FloatingComponents from '@/routes/chat/chatroom/components/floating-components/FloatingComponents'
import chatService from '@/services/chat'

export default {
    name: 'ChatroomPage',
    components: {
        ChatBody,
        ChatInput,
        ChatHeaderBar,
        DirectChatInfo,
        FloatingComponents,
        TempChatBody: () => import('@/routes/chat/chatroom/components/TempChatBody'),
    },
    props: {
        unreadCount: {
            type: Number && String && undefined,
            default: 0,
        },
    },
    data: () => ({
        agent: null,
        info: false,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        tempChatUser() {
            // 짝수 남성으로 변경 예정
            return this.$store.getters.me.gender === 0 && !this.chat.opened
        },
    },
    created() {
        this.init()
    },
    mounted() {
        this.$bus.$on('reloadMessage', this.reloadMessages)
    },
    beforeDestroy() {
        if (this.unreadCount > 0) this.updateUnreadCount()
        this.$bus.$off('reloadMessage', this.reloadMessages)
        this.$nativeBridge.postMessage({ action: 'allowSwipeGesture', value: true })
    },
    methods: {
        async init() {
            if (!this.chat || this.chat.chat_type === undefined) {
                this.$router.go(-1)
                return
            }

            await chatService.firstOpenCheck({
                user_id: this.$store.getters.me.id,
                chat_id: this.chat.id,
            })
            this.$set(this.chat, 'first_checked', true)
            // 클릭 이벤트 리스너를 달아놓으면 chatInput 포커스 된 상태에서 다른 영역 클릭 시 자동으로 포커스 해제됨
            const chatroomElement = document.querySelector('.chatroom')
            chatroomElement.addEventListener('click', event => {})

            this.updateUnreadCount()
        },
        reloadMessages() {
            const chat = this.$store.getters.chat

            if (!chat) return

            this.$nextTick(async () => {
                this.unreadCount = await this.$store.dispatch('loadChat', {
                    chatId: chat.id,
                    force: true,
                    updateUnread: false,
                })
            })
        },
        updateUnreadCount() {
            this.$bus.$emit('updateUnread')
        },
    },
}
</script>

<style lang="scss" scoped>
.chatroom {
    background: #f6f6f8;
    position: relative;
    display: flex;
    flex-direction: column;

    .header {
        position: relative;
        padding: 0 16px;
        border-bottom: 1px solid $grey-03;
    }
}
.temp-btn {
    width: 169px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;

    margin: 16px auto;

    i {
        color: black;
    }
}
</style>
