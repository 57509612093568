<template>
    <div class="chat-input">
        <div class="textarea-wrapper" :class="{ 'more-padding': moreInputPadding }">
            <div ref="height-limiter" class="flex-row items-center height-limiter">
                <i class="material-icons function-icon flex-wrap" @click="onClickFunctions"> add </i>
                <div class="input-wrapper flex-fill">
                    <textarea
                        id="txt"
                        ref="chat-input-textarea"
                        class="chat-input-textarea"
                        :disabled="disabled"
                        :placeholder="placeholder"
                        :maxlength="maxlength"
                        @input="onInput"
                        @focus="onFocus"
                        @blur="onBlur"
                        @keydown="onKeydown"
                        @keypress.exact.enter.prevent="onEnter"
                        @paste="sendPhotoWhenClipboardContainsImage"
                    />
                    <button v-show="text && text.length > 0" @click="onEnter" class="btn-send">
                        <i class="material-icons">arrow_back</i>
                    </button>
                </div>
            </div>
        </div>
        <input ref="imageUploader" type="file" class="image display-none" accept="image/*" @change="onChangeImage" />
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ChatInput',
    props: ['tempChatUser'],
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        user() {
            return this.chat.target_chat_user
        },
        closedRoom() {
            if (this.chat.target_chat_user.target_chat_user_enabled === undefined) return false

            return !this.chat.target_chat_user.target_chat_user_enabled
        },
        disabled() {
            if (this.tempChatUser) return true
            if (this.closedRoom) return true

            return !!this.$isDormant(this.chat.target_chat_user, this.chat.blocked_id)
        },
        placeholder() {
            if (this.tempChatUser) return '대화 시작 이후엔 하트가 사용되지 않아요'

            if (this.closedRoom) return '메시지 입력이 불가능합니다'

            if (this.$isDormant(this.chat.target_chat_user, this.chat.blocked_id)) {
                return `휴면회원과 대화할 수 없습니다`
            } else {
                return this.$translate('INPUT_MESSAGE')
            }
        },
        connected() {
            return this.$store.getters.connected
        },
        maxlength() {
            return 1024
        },
        dom() {
            return this.$refs['chat-input-textarea']
        },
        moreInputPadding() {
            if (this.$isAndroid() || !this.inputFocused) return

            return true
        },
        showChatInput() {
            return true
        },
    },
    data: () => ({
        text: '',
        previousText: '',
        inputFocused: false,
    }),
    watch: {
        chat(newVal, oldVal) {
            if (newVal && oldVal && newVal.id === oldVal.id) return

            setTimeout(_ => {
                if (this.dom) {
                    this.dom.focus()
                }
            }, 500)
        },
        connected(newVal) {
            if (this.dom) {
                this.dom.blur()
            }
        },
    },
    methods: {
        async hasValidMessageTicket() {
            if ((this.chat.like || {}).like_type === 'manager') return true

            if (this.$store.getters.me.gender === 1) return true
            else {
                const products = this.$store.getters.me.products
                const currentProduct = products[products.length - 1] || false
                if (!currentProduct) {
                    return await this.openChat()
                }

                const diff = this.$moment(currentProduct.valid_until).diff(
                    this.$moment(this.chat.created_at),
                    'minutes'
                )
                if (diff > 0) return true
                else {
                    return await this.openChat()
                }
            }
        },
        onInput() {
            this.text = this.dom.value
        },
        async openChat() {
            if (this.chat.opened || this.$store.getters.me.gender === 1) return true

            const myHeart = (this.$store.getters.badges || {}).heart
            const useHeart = (this.$store.getters.settings || {}).heart.start_chat
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'ChatDetailPage_Click_ChatStart',
                    option: {
                        target_user_id: this.user.id,
                        status: myHeart < useHeart ? 0 : 1,
                    },
                },
            })
            const idx = await this.$modal.basic({
                title: '대화 시작하기',
                body: `하트 ${useHeart}개를 사용해 대화를 시작합니다`,
                buttons: [
                    {
                        label: '취소',
                        class: 'btn-default',
                    },
                    {
                        label: '대화하기',
                        class: 'btn-primary',
                    },
                ],
            })

            if (idx) {
                if (myHeart < useHeart) {
                    this.$modal
                        .basic({
                            body: `하트가 ${useHeart - myHeart}개가 부족합니다. 하트를 충전하고 대화를 시작해보세요!`,
                            buttons: [
                                {
                                    label: '취소',
                                    class: 'btn-default',
                                },
                                {
                                    label: '충전하기',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 1) {
                                this.$stackRouter.push({ name: 'InAppStorePage', props: { from: 'alert' } })
                            }
                        })
                } else {
                    const onConfirm = async () => {
                        try {
                            const data = await chatService.openChat(this.chat.id)
                            this.chat.opened = true
                            this.$toast.success(data.msg)
                        } catch (e) {
                            this.$toast.error(e.data)
                        }
                    }
                    await onConfirm()
                    this.$store.dispatch('loadBadges')
                    return true
                }
            } else {
                return false
            }
        },
        onKeydown() {
            setTimeout(() => {
                this.resetTextareaHeight()
            })
        },
        onFocus() {
            this.inputFocused = true
            this.$bus.$emit('chatInputFocus')
        },
        onBlur() {
            this.inputFocused = false
            this.$bus.$emit('chatInputBlur')
        },
        resetTextareaHeight() {
            const dom = this.$refs['height-limiter']
            if (!this.text || this.dom.scrollHeight < 60) {
                dom.style.height = 'auto'
                dom.style.height = '40px'
            } else {
                dom.style.height = 'auto'
                dom.style.height = `${this.dom.scrollHeight}px`
            }
        },
        async onEnter() {
            const res = this.$isTester() ? await this.hasValidMessageTicket() : await this.openChat()

            if (res) this.send(this.text.trim())
        },
        send(text) {
            if (!this.showChatInput || !this.text || this.text.trim().length === 0) return

            if (this.dom.value) {
                this.sendAirbridgeEvent()
                const payload = {
                    text,
                    is_template: this.templateUsed,
                }
                this.$bus.$emit('onSendTextMessage', payload)
                this.dom.value = ''
                this.text = ''
            }

            this.resetTextareaHeight()
            this.dom.focus()
        },
        onClickFunctions() {
            if (!this.chat.opened) return
            this.$actionSheet({
                buttons: [
                    {
                        label: '사진 보내기 (갤러리)',
                        handler: () => {
                            this.$openGallery()
                        },
                    },
                ],
                style: {
                    'padding-bottom': '30px',
                },
            })
        },
        onChangeImage(event) {
            const sendPhoto = () => {
                const imgFile = event.blob

                this.$modal
                    .custom({
                        component: 'ModalCropper',
                        options: {
                            imgFile,
                            allowFreeAspectRatio: true,
                        },
                    })
                    .then(croppedFile => {
                        if (!croppedFile) return

                        const data = new FormData()
                        data.append('attachment', croppedFile)
                        chatService.sendPhotoMessage(this.chat.id, data)
                    })
            }
            sendPhoto()
        },
        sendPhotoWhenClipboardContainsImage(event) {
            const clipboardDataType = (event.clipboardData.items[0] || {}).type
            if (!(clipboardDataType || '').includes('image')) return

            const file = event.clipboardData.items[0].getAsFile()
            if (!file) return

            const onConfirm = () => {
                const data = new FormData()
                data.append('attachment', file)
                chatService.sendPhotoMessage(this.chat.id, data)
            }

            this.$modal
                .basic({
                    body: `<img class="btn-brd w-100" src=${URL.createObjectURL(file)}>`,
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'SEND',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        onConfirm()
                    }
                })
        },
        sendAirbridgeEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'user_send_message',
                },
            })
        },
    },
    mounted() {
        if (!this.chat.opened && !this.chat.first_checked) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Enter_Chat',
                    option: {
                        chat_id: this.chat.id,
                        status: 0,
                    },
                },
            })
        } else if (this.chat.opened) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Enter_Chat',
                    option: {
                        chat_id: this.chat.id,
                        status: 1,
                    },
                },
            })
        }
        this.$bus.$on('onClickSendPicture', this.onSendPictureRequested)
        this.$bus.$on('onRequestPhotoFromGallery', this.onChangeImage)
    },
    beforeDestroy() {
        this.$bus.$off('onClickSendPicture', this.onSendPictureRequested)
        this.$bus.$off('onRequestPhotoFromGallery', this.onChangeImage)
    },
}
</script>

<style lang="scss" scoped>
.chat-input {
    position: relative;
    border-top: solid 1px $grey-03;
    background-color: white;

    .send-contact {
        text-align: center;
        font-size: 13px;
        padding-top: 10px;
        padding-bottom: 6px;

        @include f-medium;
    }

    .textarea-wrapper {
        border-radius: 0;
        padding: 8px 16px;
        border: none;

        &.more-padding {
            padding-bottom: 28px;
        }

        .height-limiter {
            height: 40px;
            max-height: 76px;
        }

        .input-wrapper {
            height: 100%;
            border-radius: 10px;
            border: 1px solid $grey-03;
            background: #fafafa;
            display: flex;
            align-items: flex-end;

            textarea {
                background: none;
                padding: 8px 12px;
                height: 100%;
                -webkit-appearance: none;
                overflow: hidden;
                line-height: normal;
            }

            textarea::placeholder {
                @keyframes blink {
                    from,
                    to {
                        color: transparent;
                    }
                    50% {
                        color: black;
                    }
                }

                animation: 1s blink step-end infinite;
            }
        }
    }

    .function-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        color: $grey-07;
        border: solid 1px $grey-07;
        border-radius: 4px;
    }

    .btn-send {
        height: 28px;
        width: 28px;
        background-color: $purple-primary;
        border-radius: 50%;
        border: 0;
        margin-bottom: 4px;
        margin-right: 4px;
        padding: 0;

        @include center;
        @include flex-wrap;

        .material-icons {
            font-size: 20px;
            transform: rotate(90deg);
            color: white;
        }
    }
}
</style>
