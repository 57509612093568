<template>
    <div
        ref="message"
        class="message-text"
        :class="{ 'mouse-down': mouseDown }"
        @click="onClickText"
        v-html="trimText"
    />
</template>
<script>
export default {
    name: 'MessageText',
    props: ['message'],
    data: () => ({
        mouseDown: false,
        isVariationTester: false,
        variation: '',
     
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        isHasLink() {
            const regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/gi
            return regex.test((this.message.content || '').trim())
        },
        getLink() {
            const regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/gi
            return (this.message.content || '').trim().match(regex)[0]
        },
        trimText() {
            const content = (this.message.content || '').trim()

            if (this.isHasLink) {
                return content.replace(
                    this.getLink,
                    `<span class="link" style="text-decoration: underline">${this.getLink}</span>`
                )
            }

            // AB test 용
            if (this.isVariationTester && this.variation === 'B') {
                if (content === '이 분 소개 받을게요!') {
                    return '대화 한번 해볼게요'
                }
            }

            return content
        },
    },
    mounted() {
        this.initTouchEvents()
        this.initABTest()
    },
    methods: {
        onClickText() {
            if (this.isHasLink) {
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: this.getLink,
                })
            }
        },

        initTouchEvents() {
            const message = this.$refs.message
            message.addEventListener('touchstart', this.onMouseDown)
            message.addEventListener('touchend', this.onMouseUp)
            message.addEventListener('mouseleave', this.onMouseUp)
            message.addEventListener('touchmove', this.onMouseUp)
        },
        onMouseDown() {
            const doc = this
            this.mouseDown = true

            setTimeout(() => {
                if (this.mouseDown) {
                    this.$copyText(this.message.content)
                        // $copyText 라이브러리가 Uncaught in promise 하는 경우가 존재하여 resolve인 경우만 핸들링
                        .then(() => {
                            doc.$toast.success('메시지가 복사되었습니다.')
                        })
                        .catch(() => {})
                    this.mouseDown = false
                }
            }, 700)
        },
        onMouseUp() {
            this.mouseDown = false
        },
        initABTest() {
            const isFemale = this.me.gender === 1
            const isNewbie = this.me.id > this.$store.getters.introduceCTATestMinId

            this.isVariationTester = isFemale && isNewbie && this.me.id % 2 === 1
            // 신규 가입 여성
            if (this.isVariationTester) {
                this.variation = 'B'
            } else {
                this.variation = 'A'
            }
        },
    },
}
</script>

<style lang="scss" scoped>
span.link {
    text-decoration: underline !important;
}
.premium-me {
    background-image: $yellow-premium-grad !important;
    color: black !important;
}
.premium-agent {
    background: $brand-premium-blue !important;
    color: white !important;
}
</style>
