<template>
    <nav class="chat-header-bar" :class="{ instant: isInstantChat }">
        <button class="left-btn" @click="onClickLeftBtn">
            <i class="material-icons">chevron_left</i>
        </button>

        <div class="title-wrapper">
            <img class="thumbnail" v-lazy="thumbnailUrl" alt="" />
            <h1 class="title" :class="{ flexbox: isInstantChat }" v-html="title" />
            <div class="info" v-if="isDirectChat && !chat.blocked_id" @click="onClickInfoBtn">
                <span>바로 대화</span>
                <i class="material-icons-outlined">info</i>
            </div>
        </div>

        <div class="right-btn" @click="onClickRightBtn">
            <i class="material-icons">more_horiz</i>
        </div>
    </nav>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ChatHeaderBar',
    data: () => ({
        showInstantChatInfo: false,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        targetUser() {
            return this.chat.target_chat_user
        },
        thumbnailUrl() {
            if (this.chat.blocked_id || this.$isDormant(this.targetUser))
                return require('@/assets/images/blank_profile.svg')

            const photoUrl = this.targetUser.photo_url
            if (photoUrl) return photoUrl

            return this.$alternativeImg(this.targetUser.gender)
        },
        title() {
            if (this.user.id === 2414) {
                if (this.isInstantChat) {
                    return `${this.user.name} 매니저
                        <span class="expire-badge">임시</span>`
                } else {
                    return `${this.user.name} 매니저`
                }
            }
            if (this.chat.blocked_id) return '휴면회원'

            if (this.isDirectChat) return this.user.name || this.user.nickname || ''

            return this.$nameAndNick(this.user) || ''
        },
        isInstantChat() {
            return this.$isInstantChat(this.chat)
        },
        user() {
            return this.chat.target_chat_user || {}
        },
        isDirectChat() {
            const isTarget = this.chat.opener_id !== this.$store.getters.me.id
            return (this.chat.like || {}).like_type === 'direct' && isTarget
        },
    },
    methods: {
        async onExit() {
            const idx = await this.$modal.basic({
                body: '대화방을 나가면 대화내용이 모두 삭제되고 채팅목록에서 삭제됩니다.',
                buttons: [
                    {
                        label: '취소',
                        class: 'btn-default',
                    },
                    {
                        label: '나가기',
                        class: 'btn-primary',
                    },
                ],
            })
            if (!idx) return

            const res = await chatService.leaveChat({
                user_id: this.$store.getters.me.id,
                chat_id: this.chat.id,
            })
            if (res) {
                await this.$store.dispatch('loadChats', true)
                this.$toast.success('채팅방을 나갔습니다')
                this.$stackRouter.pop()
            }
        },
        onClickInfoBtn() {
            this.$emit('onClickInfoBtn')
        },
        onClickLeftBtn() {
            this.$stackRouter.pop()
        },
        onClickRightBtn() {
            const buttons = [
                {
                    id: 0,
                    label: '신고하기',
                    class: '',
                    handler: () =>
                        this.$stackRouter.push({
                            name: 'ReportSelectReasonPage',
                            props: {
                                target: this.user,
                            },
                        }),
                },
                {
                    id: 1,
                    label: '나가기',
                    class: '',
                    handler: () => setTimeout(() => this.onExit(), 400),
                },
            ]
            this.$actionSheet({
                buttons: this.user.id === 2414 ? buttons.filter(b => b.id === 0) : buttons,
            })
        },
    },
    mounted() {},
    beforeDestroy() {},
}
</script>

<style lang="scss" scoped>
.chat-header-bar {
    $chat-header-height: 52px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: $chat-header-height;
    background-color: white;

    .title-wrapper {
        display: flex;
        align-items: center;
        max-width: 70%;

        .thumbnail {
            width: 32px;
            height: 32px;
            border-radius: 13px;
            margin-right: 8px;
        }

        .info {
            margin-left: 6px;
            margin-top: 2px;
            display: flex;
            align-items: center;
            font-size: 11px;
            color: white;
            background: #ffaa00;
            border-radius: 6px;
            padding: 1px 6px;
            i {
                margin-left: 3px;
                font-size: 15px;
                color: white;
            }
        }
    }

    .title {
        color: black;
        font-size: 16px;
        font-weight: 500;

        @include lines-1;
        @include f-medium;

        &.flexbox {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
        }
    }

    .premium-agent {
        height: 20px;
        margin-left: 8px;
        padding: 1px 6px;
        font-size: 12px;
        color: $yellow-premium;
        border-radius: 6px;
        border: solid 1px $yellow-premium;

        @include center;
        .premium-agent-inner {
            vertical-align: middle;
        }
    }

    button {
        border: 0;
        background: 0;
        padding: 0;
    }

    .left-btn,
    .right-btn {
        //width: calc(#{$chat-header-height} + 16px);
        height: $chat-header-height;

        @include center;

        .material-icons {
            color: $grey-08;
        }
    }

    .left-btn {
        position: absolute;
        height: $chat-header-height;
        left: 0;
    }

    .right-btn {
        position: absolute;
        right: 0;

        .title {
            color: $pink-deep;
            @include f-regular;
        }
    }

    &.instant {
        ::v-deep .expire-badge {
            margin-left: 6px;
            padding: 0 6px;
            height: 20px;
            border-radius: 6px;
            //border: solid 1px $blue-suggest;
            background: $grey-02;
            font-size: 11px;
            color: $grey-08;

            @include f-medium;
            @include center;
        }
    }
}
</style>
