<template>
    <div class="direct-chat-info">
        <i class="material-icons float" @click="onClickExit">cancel</i>
        <div class="desc">
            <p>회원님과 대화를 꼭 나눠보고 싶어,</p>
            <p><span class="f-bold" style="color: #ffaa00">바로 대화</span>를 신청한 진정성있는 분이에요!</p>
        </div>
        <div class="more-heart f-12 m-t-4 f-regular">
            <span class="f-medium">* 바로 대화</span>는 일반 호감보다 하트가 많이 사용됩니다.
        </div>
    </div>
</template>

<script>
export default {
    name: 'DirectChatInfo',
    methods: {
        onClickExit() {
            this.$emit('exit')
        },
    },
}
</script>

<style scoped lang="scss">
.direct-chat-info {
    .float {
        float: right;
    }
    .more-heart {
        color: $grey-08;
        span {
            color: $grey-09;
        }
    }

    z-index: 10;
    position: absolute;
    width: calc(100vw - 32px);
    font-size: 14px;
    color: black;
    @include f-medium;

    top: 56px;
    left: 16px;
    background: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.05);
    padding: 16px;
    border-radius: 12px;
}
</style>
