<template>
    <div class="message-action p-relative" :class="message.mtype">
        <ActionDefaultLayout v-if="useDefaultLayout" :message="message">
            <component :is="componentByMessageType" :message="message" />
        </ActionDefaultLayout>
        <component v-else :is="componentByMessageType" :message="message" />
    </div>
</template>
<script>
import ActionDefaultLayout from './ActionDefaultLayout'

export default {
    name: 'MessageAction',
    props: ['message'],
    components: {
        ActionDefaultLayout,
        ActionPhoto: () => import('./message-actions/ActionPhoto'),
        ActionNoti: () => import('./message-actions/ActionNoti'),
        ActionUnknown: () => import('./message-actions/ActionUnknown'),
        ActionManagerSurvey: () => import('./message-actions/ActionManagerSurvey'),
        ActionCloseChat: () => import('./message-actions/ActionCloseChat'),
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        componentByMessageType() {
            if (this.unknown) return 'ActionUnknown'
            if (this.message.mtype.includes('photo')) return 'ActionPhoto'
            if (this.message.mtype.includes('open-chat')) return 'ActionNoti'

            return 'Action' + this.$case.toPascal(this.message.mtype, '-', 2)
        },
        useDefaultLayout() {
            return (
                ['alimi', 'photo'].every(key => !this.message.mtype.includes(key)) &&
                this.message.mtype !== 'close-chat' &&
                this.message.mtype !== 'request' &&
                this.message.mtype !== 'gift-info' &&
                !this.unknown &&
                this.message.mtype !== 'accept-introduce'
            )
        },
        mtypes() {
            return ['photo', 'text', 'open-chat', 'manager-survey', 'profile-photo', 'close-chat']
        },
        unknown() {
            return this.mtypes.indexOf(this.message.mtype) === -1
        },
    },
}
</script>
