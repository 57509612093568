<template>
    <div class="action-default-layout">
        <div class="content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionDefaultLayout',
    props: ['message'],
}
</script>

<style lang="scss" scoped>
.action-default-layout {
    ::v-deep button {
        height: 36px;

        @include f-medium;
    }
}
</style>
