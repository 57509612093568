<template>
    <div class="floating-components">
        <template> </template>
        <div class="instant-room" v-if="isInstantChat">
            <!-- <InstantUser /> -->
        </div>
        <template> </template>
    </div>
</template>

<script>
// import InstantUser from './InstantUser'

export default {
    name: 'FloatingComponents',
    components: {
        // InstantUser,
    },
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isInstantChat() {
            return this.$isInstantChat(this.chat)
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.floating-components {
    position: absolute;
    top: 42px;
    left: 0;
    right: 0;
    padding-bottom: 2px;
    z-index: 3;
}
</style>
